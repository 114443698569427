<template>
  <div class="c">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
  </div>
</template>

<script>
  /**
   * 消费记录组件
   */
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          search: [{
            key: "consumeTime",
            label: "支付时间",
            type: "daterange",
            timeType: "yyyy-MM-dd HH:mm:ss",
            format: "yyyy-MM-dd",
          }],
          columns: [{
              label: "订单编号",
              key: "id",
            },
            {
              label: "商品名称",
              key: "bcItemName"
            },
            {
              label: "商品数量",
              key: "num"
            },
            {
              label: "消费金额",
              key: "realAmountStr"
            },
            {
              label: "支付时间",
              key: "realPayTime"
            },
            {
              label: "支付方式",
              key: "payMethodStr"
            },
            {
              label: "支付状态",
              key: "statusStr"
            }
          ]
        }
      }
    },

    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          userId: this.$route.query.userId,
          orderType: 1,//充电卡
          state: 3,//只需要支付成功的订单
          startTime: opt.searchForm['consumeTime'] ? this.format(opt.searchForm['consumeTime'][0]) : null,
          endTime: opt.searchForm['consumeTime'] ? moment(opt.searchForm['consumeTime'][1]).format("YYYY-MM-DD 23:59:59") : null,
          ...opt.searchForm
        }
        delete dto.consumeTime;
        this.post('mall-service/bc/order/page', dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
          if (res.data.data && res.data.data.length) {
            res.data.data.forEach(item => {
              item.statusStr = ['新建支付中','支付中','支付成功','支付失败','订单取消','支付超时'][item.state - 1]
              if (item.payMethod == 1) {
                item.payMethodStr = "微信支付";
              } 
              // else if (item.payMethod == 5) {
              //   item.payMethodStr = "微信支付（使用优惠券）";
              // }
              else if (item.payMethod == 4) {
                item.payMethodStr = "会员余额支付";
              }
              else if (item.payMethod == 6) {
                item.payMethodStr = "刷卡支付";
              }
              // else if (item.payMethod == 7) {
              //   item.payMethodStr = "通联支付";
              // }
              item.realAmountStr = item.realAmount ? '￥' + Number(item.realAmount).toFixed(2) : '￥0.00';
            })
          }
          opt.cb(res.data)
        })

      },
    }
  }
</script>

<style lang="scss" scoped>
  .c {
    margin-top: 10px;
    height: 100%;
  }
</style>
<template>
  <div class="c">
    <app-header title="用户详情" :isShowBack="true"></app-header>
    <member :baseInfo="baseInfo" />
    <common-tab class="commonTab" :tabs="tabs" @tabChange="onTabChange" :tabIndex="tabIndex" />
    <keep-alive>
      <consume-record v-if="tabIndex == 0" />
      <powerChange-record v-if="tabIndex == 1"/>
      <powerRent-record v-if="tabIndex == 2"/> 
    </keep-alive>
  </div>
</template>

<script>
  /**
   * 会员详情
   */
  import CommonTab from '../../../components/common-tab.vue'
  import member from './components/member.vue'
  import powerChangeRecord from './components/powerChangeRecord.vue'
  import ConsumeRecord from './components/consumeRecord.vue'
  import powerRentRecord from './components/powerRentRecord.vue'

  export default {
    components: {
      member,
      CommonTab,
      powerChangeRecord,
      ConsumeRecord,
      powerRentRecord
    },
    name: 'MemberDetail',
    data() {
      return {
        baseInfo: {},
        tabs: [
          {
            label: '消费记录'
          },
          {
            label: '换电记录'
          },
          {
            label: '租电记录'
          }
        ],
        tabIndex: 0
      }
    },
    activated() {
      let userId = this.$route.query.userId
      this.queryDetail(userId)
      let tabIndex = this.cache.get('tramMmemberDetailTabIndex');
      if(tabIndex) {
        this.tabIndex = Number(tabIndex);
      }else {
        this.tabIndex = 0;
      }
    },
    methods: {
      onTabChange(tabIndex) {
        this.tabIndex = tabIndex;
        this.cache.set('tramMmemberDetailTabIndex',tabIndex)
      },
      queryDetail(userId) {
       this.baseInfo = {
          userId: userId
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .c {
    display: flex;
    flex-direction: column;
  }

  .commonTab {
    margin-top: 20px;
  }
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "c" },
    [
      _c("app-header", { attrs: { title: "用户详情", isShowBack: true } }),
      _c("member", { attrs: { baseInfo: _vm.baseInfo } }),
      _c("common-tab", {
        staticClass: "commonTab",
        attrs: { tabs: _vm.tabs, tabIndex: _vm.tabIndex },
        on: { tabChange: _vm.onTabChange }
      }),
      _c(
        "keep-alive",
        [
          _vm.tabIndex == 0 ? _c("consume-record") : _vm._e(),
          _vm.tabIndex == 1 ? _c("powerChange-record") : _vm._e(),
          _vm.tabIndex == 2 ? _c("powerRent-record") : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="info">
    <el-col>
      <div class="item">
        <div class="label">用户账号</div>
        <div class="value">{{memberInfo.memberName}}</div>
      </div>
      <div class="item">
        <div class="label">手机号码</div>
        <div class="value">{{memberInfo.mobile}}</div>
      </div>
      <div class="item">
        <div class="label">服务到期时间</div>
        <div class="value">{{memberInfo.bcEndDate}}</div>
      </div>
    </el-col>
    <el-col>
      <div class="item">
        <div class="label">用户ID</div>
        <div class="value">{{memberInfo.userId}}</div>
      </div>
      <div class="item">
        <div class="label">用户类型</div>
        <div class="value">{{memberInfo.memberTypeStr}}</div>
      </div>
      <div class="item">
        <div class="label">租电池时间</div>
        <div class="value">{{memberInfo.rentTime}}</div>
      </div>
    </el-col>
  </div>
</template>

<script>
  /**
   * 会员基本信息组件
   */
  export default {
    props: {
      "baseInfo": {
        type: Object,
        require: true,
        default: () => {
          return {}
        }
      }
    },
    watch: {
      baseInfo: function (newVal, oldVal) {
        this.initView(newVal.userId)
      }
    },
    data() {
      return {
        memberInfo: {
          memberName: '',
          mobile: '', 
          bcEndDate: '',
          userId: '',
          memberTypeStr:'',
          rentTime: '',
        },
      }
    },
    methods: {
      initView(data) {
        this.get('mall-service/bc/member/detail/'+ data, {}).then(res => {
          this.memberInfo = {
            memberName: res.memberName,
            mobile: res.mobile || '--',
            bcEndDate: res.bcEndDate ? moment(res.bcEndDate).format('YYYY-MM-DD') : '--',
            userId: res.userId,
            memberTypeStr: ['普通会员', '白名单', '灰名单', '灰名单'][res.memberType],
            rentTime: res.rentTime || '--',
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .info {
    display: flex;
    flex-direction: row;
    padding-left: 100px;
    padding-top: 10px;
    width: 40%;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;

    .label {
      width: 180px;
      text-align: right;
    }

    .value {
      margin-left: 20px;
      color: #5a5252;
      width: 200px;
    }
  }
</style>
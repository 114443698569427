<template>
  <div class="c">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
  </div>
</template>

<script>
  /**
   * 换电记录组件
   */
  export default {
    data() {
      return {
        opt: {
          // 字段内待定义
          columns: [{
              label: "租电编号",
              key: "id"
            },
            {
              label: "商家名称",
              key: "merchantName"
            },
            {
              label: "操作人",
              key: "memberName"
            },
            {
              label: "电池",
              key: "bcItemName"
            },
            {
              label: "租/还",
              key: "refundStateStr"
            },
            {
              label: "租借/退款金额",
              key: "realAmountStr"
            },
            {
              label: "时间",
              key: "realPayTime"
            },
            {
              label: "租电结果",
              key: "resultStr"
            },
            {
              label: "结果描述",
              key: "resultDescribe"
            }
          ]
        }
      }
    },
    methods: {
      onGet(opt) {
        let searchForm = opt.searchForm
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          userId: this.$route.query.userId,
          orderType: 2, //租电池
        }
        this.post('mall-service/bc/order/page', dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
          if (res.data.data) {
            res.data.data.forEach(item => {
              if (item.refundState == 1) {
                item.refundStateStr = '租借';
              } else if (item.refundState == 2) {
                item.refundStateStr = '归还';
              }
              if (item.state == 3) {
                item.resultStr = '成功';
              } else {
                item.resultStr = '失败';
              }
              item.resultDescribe = ['新建未支付', '支付中', '--', '支付失败', '订单取消', '支付超时'][item.state - 1]
              item.realAmountStr = item.realAmount ? '￥' + Number(item.realAmount).toFixed(2) : '￥0.00';
              item.realPayTime = item.realPayTime || '--';
            })
          }
          opt.cb(res.data)
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .c {
    margin-top: 10px;
    height: 100%;
  }
</style>
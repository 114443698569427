var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info" },
    [
      _c("el-col", [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("用户账号")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.memberInfo.memberName))
          ])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("手机号码")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.memberInfo.mobile))
          ])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("服务到期时间")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.memberInfo.bcEndDate))
          ])
        ])
      ]),
      _c("el-col", [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("用户ID")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.memberInfo.userId))
          ])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("用户类型")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.memberInfo.memberTypeStr))
          ])
        ]),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("租电池时间")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.memberInfo.rentTime))
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
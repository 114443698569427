<template>
  <div class="c">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
  </div>
</template>

<script>
  /**
   * 换电记录组件
   */
  export default {
    data() {
      return {
        opt: {
          search: [{
            key: "payTime",
            label: "换电时间",
            type: "daterange",
            timeType: "yyyy-MM-dd HH:mm:ss",
            format: "yyyy-MM-dd",
          }],
          // 字段内待定义
          columns: [{
              label: "换电编号",
              key: "id"
            },
            {
              label: "门店名称",
              key: "storeName"
            },
            {
              label: "换电电池",
              key: "itemName"
            },
            {
              label: "换电时间",
              key: "useTime"
            },
            {
              label: "换电结果",
              key: "stateStr"
            },
            {
              label: "结果描述",
              key: "resultStr"
            }
          ]
        }
      }
    },
    methods: {
      onGet(opt) {
        let searchForm = opt.searchForm
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          userId: this.$route.query.userId,
          startTime: opt.searchForm['payTime'] ? this.format(opt.searchForm['payTime'][0]) : null,
            endTime: opt.searchForm['payTime'] ? moment(opt.searchForm['payTime'][1]).format("YYYY-MM-DD 23:59:59") : null,
          ...opt.searchForm
        }
        delete dto.payTime;
        this.post('mall-service/bc/change/battery/page', dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
          if (res.data.data) {
            res.data.data.forEach(item => {
              item.storeName = item.storeName || '--';
              if(item.state == 0) {
                item.stateStr = '成功'
                item.resultStr = '--'
              } else {
                item.stateStr = '失败'
              }
              if(item.state == 1) {
                item.resultStr = '柜门弹开失败'
              } else if(item.state == 2){
                item.resultStr = '连接从柜门失败'
              } else if(item.state == 5){
                item.resultStr = '柜门已经是打开状态'
              } else if(item.state == 6){
                item.resultStr = '蓝牙芯片通讯失败'
              } else if(item.state == 30){
                item.resultStr = '其他错误'
              } else if(item.state == 100){
                item.resultStr = '设备未响应开门结果'
              } else if(item.state == 101){
                item.resultStr = '并发场景未处理的开门请求'
              } else if(item.state == 11){
                item.resultStr = '秘钥校验失败'
              }
            })
          }
          opt.cb(res.data)
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .c {
    margin-top: 10px;
    height: 100%;
  }
</style>